import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,

  rivalName: "royalplanet",
  casinoId: 162,
  GA_TRACKING: "G-2EFCT34RD0",

  rivalChatGroupName: "Royal Planet",
  prettyName: "Royal Planet",
  contactEmail: "support@royalplanet.casino",
  docsEmail: "documents@royalplanet.email",

  //SEO
  metaDescription: "Join Royal Planet Casino for premium online gaming with top slots, table games, and live dealers. Enjoy royal bonuses, a rewarding loyalty program, and secure play on desktop or mobile. Embark on a journey of big wins and endless entertainment!",
  logoName: "royal-planet-casino-online-casino-logo.png",
  logoAltText: "Royal Planet Casino official logo, featuring gold and white text with a royal theme, representing a premium online casino offering slots, table games, and exclusive promotions. Enjoy a secure and regal online gaming experience at Royal Planet Casino."

};


